import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import './Project.scss';

const Project = (props) => {
    return (
        <div className="projects">
            <div className="header">
                <FontAwesomeIcon icon={faLaptopCode} />
                <h2>{props.name}</h2>
            </div>
            <div className="desc">
                <p> {props.desc} </p>
                <div className="tech">
                    {props.skills.map((skill, index) => <span key={index} className="tech__item">#{skill}</span>)}
                </div>
                <div className="links">
                    <FontAwesomeIcon 
                        icon={faGithub}
                        onClick={() => window.open(props.links.github, "__blank")} />
                    <FontAwesomeIcon 
                        icon={faExternalLinkAlt}
                        onClick={() => window.open(props.links.web, "__blank")} />
                </div>
            </div>
        </div>
    );
}

export default Project;