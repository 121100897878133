import React, { useLayoutEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import SideNav from './../SideNav/SideNav';

import './Header.scss';

const Header = () => {
    const [isDropdownExpanded, setDropdownExpandedStatus] = useState(false);
    const listenScrollEvent = (ev) => {
        const navbar = document.querySelector('#nav');
        const lastPosition = window.scrollY;
        if (lastPosition > 50 ) {
            navbar.classList.add('active');
        } else if (navbar.classList.contains('active')) {
            navbar.classList.remove('active');
        } else {
            navbar.classList.remove('active');
        }
    }
    useLayoutEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    }, []);
    return (
        <nav className="nav" id="nav">
            <a href="#" className="nav__logo">itzzmeakhi</a>
            <ul className="nav__list">
                <li className="nav__item">
                    <Link to="/" className="nav__itemLink">
                        Home
                    </Link>
                </li>
                <li className="nav__item">
                    <a 
                        onClick={() => window.location.replace("/#about")} 
                        className="nav__itemLink">
                        About
                    </a>
                </li>
                <li className="nav__item">
                    <a 
                        onClick={() => window.location.replace("/#work")} 
                        className="nav__itemLink">
                        Work
                    </a>
                </li>
                <li className="nav__item">
                    <a 
                        onClick={() => window.location.replace("/#portfolio")} 
                        className="nav__itemLink">
                        Portfolio
                    </a>
                </li>
                <li className="nav__item">
                    <a 
                        onClick={() => window.location.replace("/#contact")} 
                        className="nav__itemLink">
                        Contact
                    </a>
                </li>
            </ul>
            <div className="nav__mobileList">
                {!isDropdownExpanded && 
                    <FontAwesomeIcon 
                    icon={faBars} 
                    onClick={() => setDropdownExpandedStatus(!isDropdownExpanded)} />}
            </div>
            <SideNav 
                expanded={isDropdownExpanded}
                setDropdownExpandedStatus={(status) => setDropdownExpandedStatus(status)} />
        </nav>
    );
}

export default Header;