import React from 'react';

import homeImgLight from './../../assets/images/home.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faFacebookF, faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

import './Home.scss';

const Home = (props) => {
    return (
        <div className="home" id="home">
            <div className="home__content">
                <h1 className="home__contentHeader">{props.header}</h1>
                <h1 className="home__contentHeader">{props.name}</h1>
                <p className="highlight">{props.role}</p>
                <p>{props.desc}</p>
                <div className="home__socialButtons">
                    <FontAwesomeIcon 
                        icon={faLinkedinIn} 
                        onClick={() => window.open("https://www.linkedin.com/in/itzzmeakhi/", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faFacebookF} 
                        onClick={() => window.open("https://www.facebook.com/itzzmeakhi", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faTwitter} 
                        onClick={() => window.open("https://twitter.com/itzzmeakhi", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faGithub} 
                        onClick={() => window.open("https://github.com/itzzmeakhi", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faInstagram} 
                        onClick={() => window.open("https://www.instagram.com/itzzmeakhi/", "__blank")} />
                </div>
                <button 
                    onClick={() => window.location.href='mailto:akhilmallidi.98@gmail.com'}
                    type="button">
                    {props.contactBtnText}
                </button>
            </div>
            <div className="home__img">
                <img src={homeImgLight} alt="akhil cover pic" />  
            </div>
        </div>
    );
}

export default Home;