import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faFacebookF, faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

import './Contact.scss';

const Contact = () => {
    return (
        <div className="contact" id="contact">
            <h1 className="contact__heading">Get in Touch</h1>
            <div className="contact__desc">
                <p>My inbox is always open. Whether you have a question or just want to say hi, I'll try my best to get back to you!</p>
                <div className="contact__social">
                    <FontAwesomeIcon 
                        icon={faLinkedinIn} 
                        onClick={() => window.open("https://www.linkedin.com/in/itzzmeakhi/", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faFacebookF} 
                        onClick={() => window.open("https://www.facebook.com/itzzmeakhi", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faTwitter} 
                        onClick={() => window.open("https://twitter.com/itzzmeakhi", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faInstagram} 
                        onClick={() => window.open("https://www.instagram.com/itzzmeakhi/", "__blank")} />
                    <FontAwesomeIcon 
                        icon={faGithub} 
                        onClick={() => window.open("https://github.com/itzzmeakhi", "__blank")} />
                </div>
                <button 
                    onClick={() => window.location.href='mailto:akhilmallidi.98@gmail.com'}
                    type="button">
                    Say Hello!
                </button>
            </div>
        </div>
    );
}

export default Contact;