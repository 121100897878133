import React from 'react';

import aboutImg from './../../assets/images/about.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './About.scss';

const About = (props) => {
    return (
        <div className="about" id="about">
            <h1 className="about__heading">About Me</h1>
            <div className="about__desc">
                <div className="about__descImg">
                    <img src={aboutImg} alt="akhil cover pic" />
                </div>
                <div className="about__descContent">
                    <p className="header">{props.header}</p>
                    <p className="desc">{props.desc}</p>
                    <p className="skills">{props.skillsTxt}</p>
                    <ul>
                        {props.skills.map((skill, index) => <li key={index}><FontAwesomeIcon icon={faChevronRight} />{skill}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default About;