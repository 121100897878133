import React from 'react';

import './Work.scss';

const Work = (props) => {
    return (
        <div className="work" id="work">
             <h1 className="work__heading">Experience</h1>
            <div className="work__desc">
                <div className="work__timeline">
                    <ul>
                        {props.exp.map((ex,index) => {
                            return (
                                <li key={index}>
                                    <div className="work__content">
                                        <h3 className="date">{ex.date}</h3>
                                        <h1>{ex.role}</h1>
                                        <p>{ex.org}</p>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Work;