import React from 'react';

import Project from './../Project/Project';

import './Portfolio.scss';

const Portfolio = (props) => {
    return (
        <div className="portfolio" id="portfolio">
            <h1 className="portfolio__heading">Portfolio</h1>
            <div className="portfolio__desc">
                {props.projects.map((proj, index) => <Project key={index} {...proj} />)}
            </div>
        </div>
    );
}

export default Portfolio;