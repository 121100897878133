import React from 'react';

import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Work from './components/Work/Work';
import Portfolio from './components/Portfolio/Portfolio';
import Contact from './components/Contact/Contact';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faHeart } from '@fortawesome/free-solid-svg-icons';

import { data } from './utils/data';

import './App.scss';

const App = () => {
    return (
        <div className="app">
            <Header />
            <div className="app__body">
                <Home {...data.home} />
                <About {...data.about} />
                <Work {...data.work} />
                <Portfolio {...data.portfolio} />
                <Contact />
                <div className="footer">
                    <p>Designed with <FontAwesomeIcon icon={faHeart} /> and <FontAwesomeIcon icon={faCoffee} /> by Akhil</p>
                </div>
            </div>
        </div>
    )
}

export default App;
