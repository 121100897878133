import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLaptop, faFileCode, faEnvelope, faUser, faTimes } from '@fortawesome/free-solid-svg-icons';

import "./SideNav.scss";

const SideNav = ({expanded, setDropdownExpandedStatus}) => {
    return (
        <React.Fragment>
        { expanded ? 
            <div className="sideNav">
                <FontAwesomeIcon 
                    title="Close"
                    icon={faTimes}
                    onClick={() => setDropdownExpandedStatus(!expanded)}/>
                <FontAwesomeIcon
                    title="Home"
                    onClick={() => {
                        setDropdownExpandedStatus(!expanded);
                        window.scroll(0,0);
                    }}
                    icon={faHome} />
                <FontAwesomeIcon
                    title="About"
                    onClick={() => {
                        setDropdownExpandedStatus(!expanded);
                        window.location.replace("/#about");
                    }}
                    icon={faUser} />
                <FontAwesomeIcon
                    title="Work"
                    onClick={() => {
                        setDropdownExpandedStatus(!expanded);
                        window.location.replace("/#work");
                    }}
                    icon={faLaptop} />
                <FontAwesomeIcon
                    title="Portfolio"
                    onClick={() => {
                        setDropdownExpandedStatus(!expanded);
                        window.location.replace("/#portfolio");
                    }}
                    icon={faFileCode} />
                <FontAwesomeIcon
                    title="Contact Me"
                    onClick={() => {
                        setDropdownExpandedStatus(!expanded);
                        window.location.replace("/#contact");
                    }}
                    icon={faEnvelope} />
            </div>
            : null }
        </ React.Fragment>
    );
}

export default SideNav;