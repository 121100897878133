export const data = {
    home: {
        header: `Hi, I'am`,
        name: 'Akhil Reddy Mallidi',
        role: 'Frontend Developer',
        desc: 'Good working experience in web design and development knowledge, producing quality work.',
        contactBtnText: 'Get in Touch'
    },
    about: {
        header: `Hello! I'm Akhil, a Software Engineer based in Chennai.`,
        desc: `I am Frontend Developer with extensive knowledge in Frontend framework's like React, Angular, and Redux data patterns. I have been graduated from the Sathyabama Institute of Science and Technology. My goal is to always build products that provide pixel-perfect, performant experiences.`,
        skillsTxt: `Here are a few technologies I've been working with recently:`,
        skills: [
            'React',
            'Redux',
            'Angular',
            'Javascript',
            'Bootstrap',
            'HTML5',
            'CSS3'
        ]
    },
    work: {
        exp: [
            { date: 'Dec 2021 - Present', org: 'Publicis Sapient', role: 'Associate Technology L2' },
            { date: 'Oct 2019 - Nov 2021', org: 'HCL Technologies', role: 'Software Engineer' },
            { date: 'Jan 2019 - April 2019', org: 'Cognizant', role: 'Business Associate Intern' }
        ]
    },
    portfolio: {
        projects: [
            { 
                name: 'React-Formsio', 
                desc: 'Form Validation library for validations in React Applications.',
                skills: ['React.js', 'ReactHooks'],
                links: {
                    github: 'https://github.com/itzzmeakhi/react-formsio',
                    web: 'https://www.npmjs.com/package/react-formsio'
                }
            },
            { 
                name: 'Shoppie', 
                desc: 'An Ecommerce application built using Angular and Firebase.',
                skills: ['Angular', 'Typescript', 'Rx.js'],
                links: {
                    github: 'https://github.com/itzzmeakhi/Shoppie',
                    web: 'https://shoppie-hosting.firebaseapp.com'
                }
            },
            { 
                name: 'iMusic', 
                desc: 'An Music player application built using React and Node Sass.',
                skills: ['React.js', 'NodeSass'],
                links: {
                    github: 'https://github.com/itzzmeakhi/music-player',
                    web: 'https://itzzmeakhi.github.io/music-player/'
                }
            }
        ]
    }
}